import * as React from "react";
import { Link } from "@mui/material";
import { LogoX, LogoXathlo } from "../LogoXathlo";
import { InternalLink } from "../common/Link";
import { pipe } from "fp-ts/function";
import { assertUnreachable } from "../../utils/assertUnreachable";
import { UrlObject } from "url";

interface Props {
    href: UrlObject;
    variant: "xathlo" | "x";
}

const height = 18;

export const HomeLink = (p: Props) => {
    return (
        <InternalLink href={p.href}>
            <Link sx={{ display: "flex", p: 1 }}>
                {pipe(p.variant, (variant) => {
                    switch (variant) {
                        case "xathlo":
                            return <LogoXathlo height={height} />;
                        case "x":
                            return <LogoX height={height} />;
                    }

                    assertUnreachable(variant);
                })}
            </Link>
        </InternalLink>
    );
};
