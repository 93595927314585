import * as React from "react";
import { Sign } from "../components/xathlo/sign/Sign";
import { useGlobalAlertsSubject } from "../components/common/GlobalAlerts";
import { constVoid, flow, pipe } from "fp-ts/function";
import { option } from "fp-ts";
import { useUser } from "../utils/supabase/useUser";
import { NextRouter, useRouter } from "next/router";
import { dashboardRoute, login } from "../routes/user";
import {
    AppBar,
    Backdrop,
    Container,
    Toolbar,
    Typography,
} from "@mui/material";
import { messages } from "../utils/i18n/i18n";
import { useTranslation } from "../utils/i18n/lang";
import { CenteredProgress } from "../components/common/CenteredProgress";
import { HomeLink } from "../components/xathlo/HomeLink";
import { homePageRoute } from "../routes/xathlo";
import Layout from "../components/xathlo/Layout";

export default () => {
    const router = useRouter();
    const { redirectTo, isSpinning } = useRedirectOnLogin(router);
    const alerts$ = useGlobalAlertsSubject();
    const m = useTranslation(msg);

    return (
        <Layout
            breadCrumbs={
                <HomeLink variant={"xathlo"} href={homePageRoute.route({})} />
            }
        >
            <Container
                maxWidth={"sm"}
                sx={{
                    py: 10,
                }}
            >
                <Typography variant={"h3"} component={"h1"} sx={{ mb: 3 }}>
                    {m.heading}
                </Typography>
                <Sign
                    router={router}
                    redirectTo={redirectTo}
                    setAlert={flow(
                        option.fold(constVoid, (alert) => {
                            alerts$.next(alert);
                        })
                    )}
                />
                <Backdrop
                    sx={{
                        color: "#fff",
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={isSpinning}
                >
                    <CenteredProgress />
                </Backdrop>
            </Container>
        </Layout>
    );
};

const msg = messages({ heading: "Login" }, { cs: { heading: "Přihlášení" } });

const useRedirectOnLogin = (router: NextRouter) => {
    const redirectTo = pipe(
        router.query,
        login.queryCodec.decode,
        option.fromEither,
        option.map((query) => query.redirectTo)
    );
    const redirectToNullable = option.toNullable(redirectTo);

    const user = useUser();
    const userNullable = option.toNullable(user);

    const [isSpinning, setIsSpinning] = React.useState(
        redirectToNullable != null
    );
    React.useEffect(() => {
        setIsSpinning(
            redirectToNullable != null &&
                !(router.isReady && userNullable == null)
        );
    }, [router.isReady, userNullable, redirectToNullable]);

    React.useEffect(() => {
        if (router.isReady && userNullable != null) {
            const redirectTo = redirectToNullable || dashboardRoute.route({});
            setIsSpinning(true);
            router.push(redirectTo);
        }
    }, [userNullable, redirectToNullable, router.isReady]);

    return { redirectTo, isSpinning };
};
